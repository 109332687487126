<template>
    <div class="merch">
        <div class="merchlink" :class="{ 'dark': darkMode }">
            <a href="https://prosperity-gospel.bandcamp.com/merch" target="_blank" rel="noreferrer noopener">
                <img src="../assets/merchpics/bandcamp front.png" width="50%">
                <img src="../assets/merchpics/sotsCS.jpg" width="50%">
                <h2 :class="{ 'dark': darkMode }">PURCHASE MERCH ON BANDCAMP</h2>
            </a>
        </div>

    </div>
</template>

<script>
export default {
    name: 'MerchStore',
    props: ['darkMode'],
    data() {
        return {
        }
    },
    methods: {
    }

}
</script>

<style scoped>
.merchlink {
    padding: 10px;
    margin: auto;
    width: 80%;
    border: 2px solid;
    max-width: 900px;
    background-color: mistyrose;
}
.merchlink.dark {
    background-color: #4b6c8e;
}
h2.dark {
    color: white;
}
.merch{
    min-height: 80vh
}
</style>