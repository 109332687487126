<template>
    <div class="show-page">
        <div>
            <h2>FUTURE SHOWS</h2>
            <table class="shows" v-if="futureShows.length > 0">
                <tr>
                    <th class="fifteen">Date</th>
                    <th class="thirty">Venue</th>
                    <th class="fifteen">City</th>
                    <th class="fourty">Lineup</th>
                </tr>
                <tr v-for="show in futureShows" v-bind:key="show.date" class="show-row" :class="{ 'dark': darkMode }"
                    @click="picHandler(show)">
                    <td>{{ show.date }}</td>
                    <td>{{ show.venue }}</td>
                    <td>{{ show.city }}</td>
                    <td>{{ show.with }}</td>
                </tr>
            </table>
            <h4 v-else>NO UPCOMING SHOWS AT THIS TIME. PLEASE WRITE TO PROSPERITYGOSPEL@FUZZ.SCIENCE FOR BOOKING.</h4>
        </div>

        <div>
            <h2>PAST SHOWS</h2>
            <table class="shows">
                <tr>
                    <th class="fifteen">Date</th>
                    <th class="thirty">Venue</th>
                    <th class="fifteen">City</th>
                    <th class="fourty">Lineup</th>
                </tr>
                <tr v-for="show in pastShows" v-bind:key="show.date" class="show-row" :class="{ 'dark': darkMode }"
                    @click="picHandler(show)">
                    <td>{{ show.date }}</td>
                    <td>{{ show.venue }}</td>
                    <td>{{ show.city }}</td>
                    <td>{{ show.with }}</td>
                </tr>
            </table>

        </div>
        <div>
            <h2 v-if="showPics">PHOTO GALLERY</h2>
            <ul class="pictures" v-if="showPics">
                <li class="pic">
                    <img :src="selectedDateFlierUrl" @click="handlePicClick(selectedDateFlierUrl)">
                </li>
                <li v-for="pic in selectedDatePicsUrls" v-bind:key="pic" class="pic">
                    <img :src="pic" @click="handlePicClick(pic)">
                </li>
            </ul>
            <PhotoModal v-if="showModal" @close="showModal = false"><img :src="selectedPic"></PhotoModal>
        </div>
    </div>
</template>

<script>
import PhotoModal from './PhotoModal.vue'

export default {
    name: 'PastShows',
    components: {
        PhotoModal
    },
    props: ['darkMode'],
    data() {
        return {
            selectedShow: undefined,
            selectedPic: undefined,
            showPics: false,
            showModal: false,
            pastShows: [
                { "date": "December 8th, 2024", "venue": "Rubies on Five Points", "city": "Durham, NC", "with": "ARKN, Hattricks", "pictures": [], "flier": "2024-12-08 flier.png" },
                { "date": "December 6th, 2024", "venue": "Commie House", "city": "Columbia, SC", "with": "ARKN, Sent to Slaughter, Crys of Agony", "pictures": [], "flier": "2024-12-06 flier.png" },
                { "date": "Nov 16th, 2024", "venue": "Art Bar", "city": "Columbia, SC", "with": "Curse of Flesh, Coffinslide, Homicyde", "pictures": [], "flier": "2024-11-16 flier.png" },
                { "date": "October 4th, 2024", "venue": "The Garden", "city": "Charleston, SC", "with": "Acanthous, Abrevity, Hound", "pictures": [], "flier": "2024_10_04 flier.jpg" },
                { "date": "July 6th, 2024", "venue": "Snug Harbor", "city": "Charlotte, NC", "with": "Blaakhol, Cramped Casket, Geeked", "pictures": [], "flier": "2024_07_06 flier.jpg" },
                { "date": "May 18th, 2024", "venue": "Charles Towne Fermentory", "city": "Charleston, SC", "with": "Abrevity, Gods", "pictures": [], "flier": "2024_05_18 flier.jpg" },
                { "date": "May 17th, 2024", "venue": "New Brookland Tavern", "city": "Columbia, SC", "with": "Daikaiju, Abacus, Aseethe, Malevich", "pictures": ["2024_05_17 01.jpg", "2024_05_17 02.jpg", "2024_05_17 03.jpg"], "flier": "2024_05_17 flier.jpg" },
                { "date": "May 1st, 2024", "venue": "The Milestone", "city": "Charlotte, NC", "with": "Jesus is the Path to Heaven, B.T. Green, Boy A/C", "pictures": [], "flier": "2024_05_01 flier.png" },
                { "date": "Apr. 27th, 2024", "venue": "Moon Dog Meadery", "city": "Raleigh, NC", "with": "Doomsday Profit, Antiquity", "pictures": ["2024_04_27_212500.jpg", "2024_04_27_212725.jpg"], "flier": "2024_04_27 flier.png" },
                { "date": "Apr. 26th, 2024", "venue": "Commie House", "city": "Columbia, SC", "with": "Angel Massacre, Lobstrocity, Disenthralled", "pictures": ["2024_04_28 1.jpg", "2024_04_28 2.jpg", "2024_04_28 3.jpg", "2024_04_28 4.jpg"], "flier": "2024_04_26 flier.png" },
                { "date": "Apr. 13th, 2024", "venue": "Static Age Records", "city": "Asheville, NC", "with": "Toadhand, Acid Jo", "pictures": ["IMG_20240413_223648634.jpg", "IMG_20240413_223652168.jpg", "IMG_20240413_225059515.jpg"], "flier": "2024_04_13 flier.png" },
                { "date": "Feb. 24th, 2024", "venue": "The Purple Buffalo", "city": "Charleston, SC", "with": "Crooked Cult, Nosey Neighbor, Pressure Falls", "pictures": [], "flier": "2024_02_24 flier.jpg" },
                { "date": "Feb. 22nd, 2024", "venue": "Grantski Records", "city": "Augusta, GA", "with": "Flagman & Flummox", "pictures": [], "flier": "2024_02_22 flier.png" },
                { "date": "Feb. 21st, 2024", "venue": "Commiehouse", "city": "Columbia, SC", "with": "Physique, Abacus, Lobstrocity", "pictures": ["2024_02_21 01.jpg", "2024_02_21 02.jpg"], "flier": "2024_02_21 flier.jpg" },
                { "date": "Jan. 5th, 2024", "venue": "Tin Roof", "city": "Charleston, SC", "with": "Prisoner, Snargle, ADHD", "pictures": ["2024_01_05 1.jpg", "2024_01_05 2.jpg", "2024_01_05 3.jpg", "2024_01_05 4.jpg", "2024_01_05 5.jpg", "2024_01_05 6.jpg", "2024_01_05 7.jpg"], "flier": "2024_01_05 flier.png" },
                {
                    "date": "Dec. 20th, 2023", "venue": "Snug Harbor", "city": "Charlotte, NC", "with": "Headripper, Loony", "pictures": [
                        "2023_12_20 1.jpg",
                        "2023_12_20 2.jpg",
                        "2023_12_20 3.jpg",
                        "2023_12_20 4.jpg",
                        "2023_12_20 5.jpg"], "flier": "2023_12_20 flier.png"
                },
                { "date": "Nov. 17th, 2022", "venue": "Soul Bar", "city": "Augusta, GA", "with": "Prickly Heat, vitamin//wave", "pictures": [], "flier": "2022_11_17 flier.jpg" },
                { "date": "Jun. 17th, 2022", "venue": "Snug Harbor", "city": "Charlotte, NC", "with": "Telepathetics, Maxuma, & Physical Digital", "pictures": [], "flier": "2022_06_17 flier.png" },
                {
                    "date": "Apr. 9th, 2022", "venue": "Art Bar", "city": "Columbia, SC", "with": "Imprint, LMI, Bad Stars", "pictures": [
                        "2022_04_10 01.jpg",
                        "2022_04_10 04.jpg",
                        "2022_04_10 05.jpg",
                        "2022_04_10 06.jpg",
                        "2022_04_10 09.jpg",
                        "2022_04_10 11.jpg",
                        "2022_04_10 14.jpg",
                        "2022_04_10 15.jpg",
                        "2022_04_10 16.jpg",
                        "2022_04_10 17.jpg",
                        "2022_04_10 18.jpg",
                        "2022_04_10 19.jpg",
                        "2022_04_10 20.jpg",
                        "2022_04_10 21.jpg",
                    ], "flier": "2022_04_09 flier.jpg"
                },
                {
                    "date": "Mar. 6th, 2022", "venue": "New Brookland Tavern", "city": "Columbia, SC", "with": "Nixil, Krypt, & Bathe", "pictures": [
                        "2022_03_06 R0048792.jpg",
                        "2022_03_06 R0048793.jpg",
                        "2022_03_06 R0048795.jpg",
                        "2022_03_06 R0048796.jpg",
                        "2022_03_06 R0048798.jpg",
                        "2022_03_06 R0048799.jpg",
                        "2022_03_06 R0048801.jpg",
                        "2022_03_06 R0048804.jpg",
                        "2022_03_06 R0048805.jpg",
                    ], "flier": "2022_03_06 flier.png"
                },
                { "date": "Feb. 14th, 2022", "venue": "New Brookland Tavern", "city": "Columbia, SC", "with": "Frost Coffin, Bather", "pictures": [], "flier": "2022_02_14 flier.png" },
                {
                    "date": "Oct. 9th, 2021", "venue": "Snug Harbor", "city": "Charlotte, NC", "with": "The Reticent", "pictures": [
                        "2021_10_09 flier2.jpg",
                        "2021_10_09 R0048642.jpg",
                        "2021_10_09 R0048643.jpg",
                        "2021_10_09 R0048645.jpg",
                        "2021_10_09 R0048647.jpg",
                        "2021_10_09 R0048648.jpg",
                        "2021_10_09 R0048649.jpg",
                        "2021_10_09 R0048652.jpg",
                        "2021_10_09 R0048654.jpg",
                        "2021_10_09 R0048655.jpg",
                        "2021_10_09 R0048657.jpg",
                        "2021_10_09 R0048662.jpg",
                        "2021_10_09 R0048666.jpg",
                        "2021_10_09 R0048667.jpg",
                        "2021_10_09 R0048669.jpg",
                        "2021_10_09 R0048670.jpg",
                        "2021_10_09 R0048671.jpg",
                        "2021_10_09 R0048672.jpg",
                        "2021_10_09 R0048676.jpg",
                        "2021_10_09 R0048677.jpg",
                        "2021_10_09 R0048678.jpg",
                        "2021_10_09 R0048679.jpg",
                        "2021_10_09 R0048680.jpg",
                        "2021_10_09 R0048681.jpg",
                        "2021_10_09 R0048682.jpg",
                        "2021_10_09 R0048683.jpg",
                        "2021_10_09 R0048690.jpg",
                        "2021_10_09 R0048704.jpg",
                    ], "flier": "2021_10_09 flier.png"
                },
            ],
            futureShows: [

            ]
        }
    },
    methods: {
        picHandler(show) {
            if (this.selectedShow == show) {
                this.showPics = !this.showPics
            } else {
                this.selectedShow = show
                this.showPics = true
            }
        },
        handlePicClick(pic) {
            this.showModal = true;
            this.selectedPic = pic;
        }
    },
    computed: {
        selectedDatePicsUrls() {
            const picsUrls = []
            if (!this.selectedShow || !this.selectedShow?.pictures) { return [] }
            this.selectedShow.pictures.map((picture) => {
                const imgURL = require('../assets/showpics/' + picture)
                picsUrls.push(imgURL)
            });
            return picsUrls
        },
        selectedDateFlierUrl() {
            if (!this.selectedShow || !this.selectedShow?.flier) { return [] }
            return require('../assets/fliers/' + this.selectedShow.flier)
        }
    }

}
</script>

<style scoped>
html {
    height: 100vh;
}

th.fifteen {
    width: 15%;
}

th.thirty {
    width: 30%;
}

th.fourty {
    width: 40%;
}

tr,
td {
    padding: 10px;
}

table,
th,
td {
    border: 1px solid;
    border-collapse: collapse;
    font-size: small;
}

.shows {
    margin: auto;
    width: 100%;
    max-width: 900px;

}

.show-page {
    min-height: 80vh
}

.show-row:hover {
    background-color: mistyrose;
    cursor: pointer;
}

.show-row:hover.dark {
    background-color: #4b6c8e;
    cursor: pointer;
}

.pictures {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
    margin: auto;
    padding-top: 10px;
    justify-content: center;
    transform: translateX(-20px);

}

.pictures>li {
    list-style-type: none;
    max-height: 400px;
    max-width: 400px;
    position: relative;

}

.pictures>li:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pictures li img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    vertical-align: middle;
    cursor: pointer;
}
</style>