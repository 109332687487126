<template>
  <div :class="darkMode ? 'dark-mode' : 'light-mode'">
    <Menu @change-page="updateSelectedPage" @change-mode="toggleMode" :dark-mode="darkMode" />
    <component :is="selectedPage" :dark-mode="darkMode" />
    <div class="bottom"></div>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Bio from './components/Bio.vue'
import Landing from './components/Landing.vue'
import Discography from './components/Discography.vue'
import Shows from './components/Shows.vue'
import Videos from './components/Videos.vue'
import MerchStore from './components/MerchStore.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Bio,
    Landing,
    Discography,
    Shows,
    Videos,
    MerchStore,

  },
  beforeMount() {
    if (this.$cookies.isKey('darkMode')) {
      const oldCookie = this.$cookies.get('darkMode')
      const boolValue = (oldCookie === "true"); 
      this.darkMode = boolValue
    } else {
      this.$cookies.set('darkMode', this.darkMode)
    }
  },
  data() {
    return {
      selectedPage: 'Landing',
      darkMode: false,
    }
  },
  methods: {
    updateSelectedPage(newPage) {
      this.selectedPage = newPage
    },
    toggleMode() {
      this.darkMode = !this.darkMode
      this.$cookies.set('darkMode', this.darkMode)
    }
  }
}
</script>

<style>
#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
}

.dark-mode {
  color: white;
  background-color: #2c3e50;
}

.light-mode {
  color: #2c3e50;
  background-color: white;
}

a:link,
a:visited {
  color: black
}

.bottom {
  height: 60px;
}
</style>