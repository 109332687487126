<template>
    <div class="body">
        <div class="video" v-for="video in videos" v-bind:key="video.src">
            <iframe class="youtube" :src="video.src" title="video.title" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

    </div>
</template>

<script>


export default {
    name: 'VideosPage',
    components: {

    },
    props: {

    },
    data() {
        return {
            videos: [
                { "src": "https://www.youtube.com/embed/nuyyrs5H8R4", "title": "Prosperity Gospel - Live in Durham 04/27/2024" },
                { "src": "https://www.youtube.com/embed/6efs3Yvp6KM?start=1000&end=2000;", "title": "Toadhand, Prosperity Gospel &amp; Acid Jo LIVE at Static Age Records 4-13-2024" },
                { "src": "https://www.youtube.com/embed/7gINIj74GnI", "title": "AFBM Network - Prosperity Gospel - Society of the Spectral (Full album PREMIERE, 2023)" },
                { "src": "https://www.youtube.com/embed/HiOvcCGGExo?si=_6MUkJ91aFy-raAy", "title": "YouTube video player" },
                { "src": "https://www.youtube.com/embed/RCy1gOjj2V4", "title": "AFBM Network - Prosperity Gospel - Violently Pulled From Bliss (Full Album)" },
            ]
        }
    },
    methods: {
    }

}
</script>

<style scoped>
.video {
    margin: auto;
    margin-bottom: 15px;
    max-width: 900px;
    width: 80%;
    height: 400px;

}

.video:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.youtube {
    width: 100%;
    height: 100%;
}
</style>