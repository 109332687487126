<template>
    <div class="body">

        <div v-for="release in releases" class="release" v-bind:key="release.title"  :class="{ 'dark': darkMode }">
            <div class="bc-wrapper">
                <iframe class="bc-embed"
                    :src="`https://bandcamp.com/EmbeddedPlayer/album=${release.bcAlbumNumber}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/`"
                    seamless><a :href="release.bcLink">{{ release.title }} by PROSPERITY GOSPEL</a></iframe>
            </div>
            <h2 class="release-title">"{{ release.title }}"</h2>
            <h3 class="release-date">released {{ release.date }}</h3>
            <div v-for="song in songs[release.title]" v-bind:key="song.songTitle" class="lyric-row" :class="{ 'dark': darkMode }" 
                @click="lyricHandler(release.title, song.songTitle)">
                <p class="song-title">{{ song.songTitle }}</p>
                <div class="selected-lyrics"
                    v-if="showLyrics && (selectedLyrics.album == release.title) && (selectedLyrics.songTitle == song.songTitle)"
                    v-html="selectedLyrics.lyrics" />
                </div><br>
            <a :href="release.s3" class="release-date">FREE DIRECT DOWNLOAD HERE</a>
        </div>

        <button class="comps" :class="{ 'dark': darkMode }" @click="showComps = !showComps">
            <h3>{{ !showComps ? 'SHOW' : 'HIDE' }} ALL LIVE/COMPILATION RELEASES</h3>
        </button>

        <div v-if="showComps">
            <div v-for="release in compReleases" class="release"  :class="{ 'dark': darkMode }" v-bind:key="release.title">
                <iframe class="bc-embed"
                    :src="`https://bandcamp.com/EmbeddedPlayer/album=${release.bcAlbumNumber}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/`"
                    seamless><a :href="release.bcLink">{{ release.title }} by PROSPERITY GOSPEL</a></iframe>
                <h2 class="release-title">{{ release.title }}</h2>
                <h3 class="release-date">released {{ release.date }}</h3><br>
                <h3 class="release-desc">{{ release.desc }}</h3>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BioComp',
    props: ['darkMode'],
    data() {
        return {
            showComps: false,
            showLyrics: false,
            selectedLyrics: undefined,
            releases: [
                { "title": "Society of the Spectral", "date": "November 30, 2023", "desc": "Full length studio LP", "src": "sots.jpg", "bcAlbumNumber": "4116870700", "bcLink": "https://prosperity-gospel.bandcamp.com/album/society-of-the-spectral", "s3": "https://pg-site-bucket.s3.amazonaws.com/Prosperity+Gospel+-+Society+of+the+Spectral.zip" },
                { "title": "Violently Pulled From Bliss", "date": "March 31st, 2021", "desc": "Full length studio LP", "src": "vpfb.jpg", "bcAlbumNumber": "4255103012", "bcLink": "https://prosperity-gospel.bandcamp.com/album/violently-pulled-from-bliss", "s3": "https://pg-site-bucket.s3.amazonaws.com/Prosperity+Gospel+-+Violently+Pulled+From+Bliss.zip" },
            ],
            compReleases: [
                { "title": "Forest Summoner - Spring Equinox", "date": "March 20th, 2024", "desc": "Our song 'Transforming' was featured on this compilation, with sales going directly towards the reforestation of 2.5 acres of Mojave Desert.", "src": "fsse.png", "bcAlbumNumber": "2641802831", "bcLink": "https://forestsummoner.bandcamp.com/album/spring-equinox-compilation-2024" },
                { "title": "Free Palestine - Comp I", "date": "November 3, 2023", "desc": "Our song 'Atomizing' featuring Nathan Kwon of Crawl Across the Sky was featured on this compilation from Forest Summoner, with sales going towards aid to Gaza.", "src": "fspc.jpg", "bcAlbumNumber": "1337495089", "bcLink": "https://forestsummoner.bandcamp.com/album/free-palestine-comp-i" },
                { "title": "Live In The Red Room", "date": "March 1, 2023", "desc": "three song live EP and companion video, recorded for WUSC-FM", "src": "litrr.jpg", "bcAlbumNumber": "1089357479", "bcLink": "https://prosperity-gospel.bandcamp.com/album/live-in-the-red-room" },
                { "title": "Black Metal Rainbows", "date": "November 4, 2022", "desc": "Our song 'Having Into Appearing' was featured on this compilation benefiting LGBTQIA youth", "src": "bmr.jpg", "bcAlbumNumber": "4084908151", "bcLink": "https://blackmetalrainbows.bandcamp.com/album/black-metal-rainbows" },
                { "title": "Black Metal Ist Klassenkrieg", "date": "January 7, 2022", "desc": "Our song 'Thus Far and No Further' was featured on this compilation from the Anti-Fascist Black Metal Network", "src": "bmikk.jpg", "bcAlbumNumber": "2628869638", "bcLink": "https://antifascistblackmetalnetwork.bandcamp.com/album/black-metal-ist-klassenkrieg" },
            ],
            songs: {
                "Society of the Spectral": [
                    { "album": "Society of the Spectral", "songTitle": "1) Being into Having", "lyrics": "No longer beings<br> Human accumulators<br> Every last truth<br> All intuitive guidance<br> Enveloped by spectacle<br> An autonomous image<br> A weapon can't be raised<br> against itself<br> <br> When'd you realize<br> The joy of the hoard<br> The emptiness of want<br> The connection of surplus<br> Minimalism<br> Maximalism<br> All life feeds<br> The hungry maw<br> <br> Submit or die<br> Accept this truth<br> You cannot escape<br> From within or without<br> Every guidebook<br> Every tool<br> Written by the enemy<br> Forged by the fool<br> <br> When'd you realize<br> The joy of pursuit had become<br> Your real death<br> Your surrogate birth<br> Extraction<br> Introspection<br> All life feeds<br> The hungry maw<br>" },
                    { "album": "Society of the Spectral", "songTitle": "2) Having into Appearing", "lyrics": "Take this axe,<br> split this tree<br> Between molecule and category<br> <br> struck down by thirst<br> for the real<br> By an augmented stream<br> <br>Appearing<br> <br> Alienated<br> mediated<br> A gilded private moment hammered into replica<br> <br> Can't circumvent<br> The viewfinder<br> Implanted inside your skull<br> <br>Appearing<br><br> 'that which appears is good,<br> that which is good appears'<br> <br> what lies beyond commodification<br> is the deep black rot we hold inside<br> so disappear into formlessness<br> Disappear into rot<br> <br> still, a wretched thing<br> squirming<br> is born in a factory<br>" },
                    { "album": "Society of the Spectral", "songTitle": "3) Atomizing", "lyrics": "'The spectacle is nothing more<br> than an image of happy unification<br> surrounded by desolation and fear<br> at the tranquil center of misery.'<br> <br> Separation perfected<br> Mesh network of splintered beings<br> False consciousnesses connected<br> Spectators consume with no meaning<br> <br> Wide area synchronous grid<br> A scatter plot cooked up in secret<br> A life encapsulated in a two dimensional blip<br> A remote handler collecting biometrics<br> <br>Line of best fit<br> Marching in place<br> Alone and equidistant<br> Atomic units - atomic age<br> <br> Tendril stake interconnection<br> Emerging only to transfer value<br> Reactionary reproduction<br> Of the only social relation left<br> <br> Zero day identity hijacking<br> Lonely meltdowns in parallel timelines<br> Embalmed in future proof cemetery plots<br> Rotting alone surrounded by thousands<br>" },
                    { "album": "Society of the Spectral", "songTitle": "4) Generalizing", "lyrics": "'renounce all autonomous qualities in order to identify with the general law of obedience to the course of things.'<br> <br> The body<br> appears without reply.<br> Hegemonic integration<br> we harmonize into<br> meaningless impotence<br> <br> Renounce your aberrations<br> Renounce your aberrations<br> obedience to the blur of time<br> obedience in the time of blur<br> <br> The body<br> It yammers to us.<br> Everyone a cellular member<br> of a screeching tongue<br> Howling with no directive<br> a decoy<br> a false consciousness<br>" },
                    { "album": "Society of the Spectral", "songTitle": "5) Transforming", "lyrics": "Sheared, grinding transmission<br> Transforming into curls of bright stainless and black oil,<br> Gunmetal blue marshland<br> caution tape swamps, lit by yellow flame<br> <br>Signals signifying nothing<br> Creeps in this petty pace, day upon day<br> Death's dusty candle has finally gone out<br> Signals signifying nothing<br> Creeps in this petty pace, day upon day<br> Death<br> <br> Generator combusting the last cemetery coal<br> Human oil feeding an array of neon signs<br> Self driving big rigs out of the shenandoah<br> low fields low fences blot out the miles<br> <br> Textiles into nests, predators grazed upon,<br> Deer growing accustomed to the highway<br> Boxcars into dens, incinerators broken down<br> Perched over holy lands<br>" },
                    { "album": "Society of the Spectral", "songTitle": "6) Vibrating", "lyrics": "Volcanic cavalcade rumbles,<br> Vibrating across savannas.<br> Jungles lie flat as the ocean<br> With rafts of swords and spears<br> Unspent nuclear shells pierce the horizon<br> <br> Pyroclastic songs<br> Clanging throughout the caldera<br> Echoes of the dead<br> <br> Ten city blocks abuzz with music:<br> sickened glass resonators,<br> hurdy gurdy loopings,<br> angle grinder blues,<br> Lava flows where subways once snaked<br> Through ancient burial sites<br> Wash your feet in a hot spring<br> <br> Let the earth be silent after ye<br>" },
                    { "album": "Society of the Spectral", "songTitle": "7) Flying", "lyrics": "Flying on wings of carbon fiber<br> Hubris luxurious downward spiral<br> The first life of the new<br> The last plane crash<br> <br> The sun setting on the years<br> The Fates surmounted by growing scorn<br> The first life of the new<br> The last plane crash<br>" },
                ],
                "Violently Pulled From Bliss": [
                    { "album": "Violently Pulled From Bliss", "songTitle": "1) Thus Far and No Further", "lyrics": "thus far and no further: <br>one skyscraper sets the limit <br>the elevator's crowded <br>sick with fucking starving <br> <br>fragmented monolith <br>no one can see its entirety <br>tangled roots and rusting steel <br>hidden watchers peer down on me <br> <br>when will these towers fall? <br>they will be here long after us <br>power flows to wickedness <br>can we intercept and remain unchanged?" },
                    { "album": "Violently Pulled From Bliss", "songTitle": "2) Scattered Between the Roots", "lyrics": "we're exhausted by the days, <br>we're wheezing through the nights. <br>our duty is to pass without a trace, <br>pack it in, pack it out. <br>these graves are hidden, <br>not surrounded by plastic mountains, <br>small footstones slowly carpeted by ash, <br>becoming starry with soot as history burns away. <br>before your nerves relate the noose around your neck, <br>a quiet death leaves you scattered between the roots. <br>blood soaking into leaves and dust, <br>a quiet death leaves nothing eternal behind. <br> <br>who would force an heir, <br>when the keys to the kingdom are lost? <br>let them tarnish and dissolve. <br>no one to know, no reason to care, <br>these graves are mental, <br>placeholders where life never existed. <br>a psychic totem receives your suffering. <br>avoid another needless entrance into a worthless world!" },
                    { "album": "Violently Pulled From Bliss", "songTitle": "3) Norman Unclenching His Jaw from the Tail of the World", "lyrics": "from a room <br>in the clouds <br>behind a blank face <br>hunger grows <br> <br>the world eater bloats <br>strangling its neighbors <br>flesh without end <br>unqualified wholeness <br> <br>no more pain <br>all swells joy <br>bursting with comfort <br>still hunger grows <br> <br>no desire <br>but for desire <br>mourning quenching, <br>slaking bliss <br>" },
                    { "album": "Violently Pulled From Bliss", "songTitle": "4) Eyes on the Ark", "lyrics": "glazed over from whiskey and malice <br>the leyden jar of hopeless desolation <br>peeling like sunburn <br>sloughing like hope <br>as we pray <br>-eyes on the ark <br> <br>dripping down <br>onto claw and tooth <br>feast your eyes <br>on the holy truth <br>now forswear <br>your one-time prayer <br>of blind annihilation <br>ablation <br> <br>veiled in silk, gilded in gold <br>the glory of the void obliterates us <br>rejoicing and sober <br>laughing insane <br>all is now unseen <br>-eyes on the ark <br>blindness and intoxication <br>an eternal song <br>over the waves <br>where reason has drowned" },
                    { "album": "Violently Pulled From Bliss", "songTitle": "5) The Gleaners", "lyrics": "they set into action <br>a plan to extract <br>whatever they can get <br>without a complaint. <br>ignore this quiet, muffled war <br> <br>all unique, all distinct <br>all seeing, all understanding <br>all seething, all blundering <br>our consciousness betrayed us <br>crop circles <br>they are the gleaners <br>we are the chaff <br> <br>soon a man will set into action <br>a plan to eject <br>but away from us <br>he will live out his uselessness <br>in a quiet, muffled universe" },
                    { "album": "Violently Pulled From Bliss", "songTitle": "6) Pulled From Bliss", "lyrics": "violently pulled from bliss <br>to satisfy selfish requirements <br>a mirror image which now decays <br>most will have their own one day <br> <br>i was once nonexistent <br>joined with the infinite <br>pulled from bliss <br> <br>we were just possibilities <br>swimming in obsidian seas <br>until we were plucked with newfound sight <br>now surveilling our abysmal lives <br> <br>eons from our sightless past <br>given a body which will not last <br> <br>i was once nonexistent <br>joined with the infinite <br>spatially flat and unexposed <br>no inner voice or insistent ego <br>eons from our sightless past <br>given a body which will not last" },
                    { "album": "Violently Pulled From Bliss", "songTitle": "7) Pine Needles and Swamp Gas", "lyrics": "deep evergreen forest <br>dancing lights <br>find a resting place <br>a bed among the needles <br>where i can decay <br> <br>night falls quickly <br>swamp gas fills the air <br>glimpse the monsters <br>where they burrow, until they disappear <br> <br>i will become the mold <br>i will be cloaked by the pines <br>scorched by sulfur <br>which overwhelms my head <br>i will become the mold <br>i will be cloaked by the pines <br> <br>blighted and lightheaded <br>vertigo kneads us into <br>an abstract portrait of the <br>terrestrial and extraterrestrial, too" },
                ],
            }
        }
    },
    methods: {
        lyricHandler(title, songTitle) {
            if (this.selectedLyrics?.songTitle == songTitle) {
                this.showLyrics = false
                this.selectedLyrics = undefined
                return
            }
            this.showLyrics = true
            this.selectedLyrics = this.songs[title].find((element) => element.songTitle == songTitle);
        },
        getImgUrl(img) {
            return require('@/assets/releases/' + img);
        }
    }

}
</script>

<style scoped>
h2,
h3 {
    margin: 0;
}

.bc-embed {
    border: 0;
    width: 40vw;
    height: 40vw;
    max-width: 500px;
    max-height: 500px;
}

.comps {
    background-color: mistyrose;
    border: solid 2px black;
    padding: 25px;
    margin: 3%;
}
.comps:hover {
    cursor: pointer;
}
.comps.dark {
    background-color: #4b6c8e;
    border-color: white;
    color: white;
}

.body {
    margin: 0 auto;
}

.lyric-row {
    padding: 6px;
    width: fit-content;
    margin: auto;
}

.lyric-row:hover {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}
.lyric-row:hover.dark{
    background-color: #2c3e50;

}

.release {
    margin: auto;
    width: 60%;
    align-content: center;
    padding: 30px;
    border: 2px solid;
    max-width: 600px;
    margin-bottom: 50px;
    ;
    background-color: mistyrose;
}
.release.dark {
    background-color: #4b6c8e;
}

.release-art {
    width: 40%;
}

.release-date {
    font-style: italic;
    font-size: medium;
    padding: 5px;
}

.release-desc {
    font-style: italic;
    margin: auto;
    width: 100%;
}

.release-bc {
    margin: auto;
    padding-top: 3%;
}

.selected-lyrics {
    margin-top: 10px;
    font-size: small;
}

.song-title {
    padding: 0;
    margin: 0;
    font-size: medium;
}
</style>