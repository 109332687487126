<template>
    <img alt="prosperity gospel logo" v-bind:src="imgSrc" class="header-logo"
        @click="$emit('changePage', 'Landing'); selectPage('Landing')">
    <div class="menu-bar">
        <div class="links" v-for="link in links" v-bind:key="link">
            <span class="link" :class="{ selected: (selectedPage == link.pageName), 'dark': darkMode }"
                @click="$emit('changePage', link.pageName); selectPage(link.pageName)">{{ link.name }}
            </span>
        </div>
        <div @click="$emit('change-mode')"><img class="dark-toggle" src="../assets/darkmode.svg"></div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: ['darkMode'],
    data() {
        return {
            selectedPage: 'Landing',
            links: [
                { name: 'home', pageName: 'Landing' },
                { name: 'bio', pageName: 'Bio' },
                { name: 'shows', pageName: 'Shows' },
                { name: 'discography', pageName: 'Discography' },
                { name: 'videos', pageName: 'Videos' },
                { name: 'merch', pageName: 'MerchStore' },
            ]
        }
    },
    methods: {
        selectPage(newPage) {
            this.selectedPage = newPage;
        }
    },
    computed: {
        imgSrc() {
            if (this.darkMode) {
                return require('../assets/logowhite.png')
            } else {
                return require('../assets/logo.png')
            }
        }
    }

}
</script>

<style scoped>
.header-logo {
  height: 20vh;
  cursor: pointer;
  margin: auto;
}
.links {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.link {
    padding: 5px 7px;
    border: solid 3px;
    margin: 2px;
    cursor: pointer;
}

.link:hover {
    background-color: mistyrose;
}
.selected {
    background-color: mistyrose;
}
.link:hover.dark {
    background-color: #4b6c8e;
}
.selected.dark {
    background-color: #4b6c8e;
}
.dark-toggle {
    position: absolute;
    top: 1%;
    right: 1%;
    height: 25px;
    padding: 5px;
    cursor: pointer;
}
.menu-bar {
    padding: 20px;
}
</style>